<template>
  <div v-if="$apollo.loading">Loading...</div>
  <div v-else>
    <v-form class="form" @submit.stop.prevent="submit">
      <div class="main " style="background:#fff;">
        <div class="section">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <v-card dark color="teal" class="">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <h2 class="display-3">Profile Builder</h2>

                        <p>
                          This profile is the information that you want to
                          publish for retailers to find you. This is seperate
                          from the business info on your initial company
                          application.
                        </p>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <div class="col-lg-6">
                <v-card>
                  <v-toolbar color="grey" dark>
                    <v-spacer></v-spacer>

                    <button type="submit" class="btn btn-success mr-2">
                      Save & Continue Edit
                    </button>
                  </v-toolbar>

                  <v-divider></v-divider>

                  <v-list flat subheader three-line>
                    <v-list-item>
                      <template #default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            v-model="company.profile.Published"
                            class="checkboxpublish"
                            color="green darken-3"
                            hide-details
                            :input-value="active"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title
                            >Publish Your Profile</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Have your business go live in our discovery center

                            <br />

                            <v-btn
                              text
                              :href="
                                'https://www.goosedirect.com/vendor/' +
                                  $store.state.auth.user.company_relation +
                                  '/detail'
                              "
                              target="_blank"
                            >
                              Preview
                            </v-btn>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list>
                </v-card>
              </div>
            </div>

            <!-- 
           
                <v-card flat>
                  <v-card-text>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-4">
                          <img
                            alt="Raised Image"
                            src="/media/retail2.jpg"
                            class="img-raised rounded img-fluid"
                          />
                        </div>
                        <div class="col-lg-8">
                          <h3 class="title">
                            Build your profile with Goose...
                          </h3>
                          <p>
                            Great to have you onboard. Now that you have taken
                            flight on Goose, it is time to first build your
                            profile so that retailers can discover and buy your
                            great products. Fill in the details on the following
                            steps and our handy dandy Goose platform will build
                            you a profile page.
                          </p>
                          <p>
                            Once you are ready to go, check the publish box
                            above and get ready to match with some truly great
                            partners. <br />
                          </p>
                          <div class="blockquote">
                            <p>
                              “And thank you for joining Goose.”
                            </p>
                            <small> Josh Penfold - CEO </small>
                          </div>
                      
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </v-card> -->

            <v-card>
              <v-card-title class="mb-6">Contact Info</v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label">Company Name</label>
                      <div class="col-8">
                        <b-form-input
                          id="Companyname"
                          v-model="company.profile.profile_company_name"
                          class="form-control"
                          name="companyname"
                          :state="validateState('companyname')"
                          aria-describedby="input-1-live-feedback"
                          placeholder=""
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                          Company name is required.
                        </b-form-invalid-feedback>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label">City</label>
                      <div class="col-8">
                        <input
                          id="profile_city"
                          v-model="company.profile.profile_city"
                          class="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label">State</label>
                      <div class="col-8">
                        <select
                          v-model="company.profile.profile_state"
                          class="custom-select form-control"
                        >
                          <option value="AL">Alabama</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA">California</option>
                          <option value="CO">Colorado</option>
                          <option value="CT">Connecticut</option>
                          <option value="DE">Delaware</option>
                          <option value="DC">District Of Columbia</option>
                          <option value="FL">Florida</option>
                          <option value="GA">Georgia</option>
                          <option value="HI">Hawaii</option>
                          <option value="ID">Idaho</option>
                          <option value="IL">Illinois</option>
                          <option value="IN">Indiana</option>
                          <option value="IA">Iowa</option>
                          <option value="KS">Kansas</option>
                          <option value="KY">Kentucky</option>
                          <option value="LA">Louisiana</option>
                          <option value="ME">Maine</option>
                          <option value="MD">Maryland</option>
                          <option value="MA">Massachusetts</option>
                          <option value="MI">Michigan</option>
                          <option value="MN">Minnesota</option>
                          <option value="MS">Mississippi</option>
                          <option value="MO">Missouri</option>
                          <option value="MT">Montana</option>
                          <option value="NE">Nebraska</option>
                          <option value="NV">Nevada</option>
                          <option value="NH">New Hampshire</option>
                          <option value="NJ">New Jersey</option>
                          <option value="NM">New Mexico</option>
                          <option value="NY">New York</option>
                          <option value="NC">North Carolina</option>
                          <option value="ND">North Dakota</option>
                          <option value="OH">Ohio</option>
                          <option value="OK">Oklahoma</option>
                          <option value="OR">Oregon</option>
                          <option value="PA">Pennsylvania</option>
                          <option value="RI">Rhode Island</option>
                          <option value="SC">South Carolina</option>
                          <option value="SD">South Dakota</option>
                          <option value="TN">Tennessee</option>
                          <option value="TX">Texas</option>
                          <option value="UT">Utah</option>
                          <option value="VT">Vermont</option>
                          <option value="VA">Virginia</option>
                          <option value="WA">Washington</option>
                          <option value="WV">West Virginia</option>
                          <option value="WI">Wisconsin</option>
                          <option value="WY">Wyoming</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label">Linkedin</label>
                      <div class="col-8">
                        <input
                          id="profile_website"
                          v-model="company.profile.linkedin"
                          class="form-control"
                          type="text"
                        />
                        <small id="emailHelp" class="form-text text-muted">
                          Please use full URL.</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group row">
                      <label
                        for="contact-email-input"
                        class="col-4 col-form-label"
                        >Contact Email</label
                      >
                      <div class="col-8">
                        <b-form-input
                          id="contact-email"
                          v-model="company.profile.profile_contact_email"
                          class="form-control"
                          name="contact-email"
                          :state="validateState('email')"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                          Email is required and a valid email address.
                        </b-form-invalid-feedback>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label">Phone</label>
                      <div class="col-8">
                        <input
                          id="profile_phone"
                          v-model="company.profile.profile_phone"
                          class="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label">Website</label>
                      <div class="col-8">
                        <input
                          id="profile_website"
                          v-model="company.profile.profile_website"
                          class="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label">Instagram</label>
                      <div class="col-8">
                        <input
                          id="profile_website"
                          v-model="company.profile.instagram"
                          class="form-control"
                          type="text"
                        />
                        <small id="emailHelp" class="form-text text-muted">
                          Please use full URL.</small
                        >
                      </div>
                    </div>

                       <div class="form-group row">
                      <label class="col-4 col-form-label">Facebook</label>
                      <div class="col-8">
                        <input
                          id="profile_website"
                          v-model="company.profile.facebook"
                          class="form-control"
                          type="text"
                        />
                        <small id="emailHelp" class="form-text text-muted">
                          Please use full URL.</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-title class="mb-6">Business Info</v-card-title>
              <v-card-text>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group row">
                      <label class="col-5 col-form-label"
                        >Business Category</label
                      >
                      <div class="col-7">
                        <b-form-select
                          v-model="company.profile.profile_category"
                          :options="categoryOptions"
                        ></b-form-select>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-5 col-form-label"
                        >Average Margin %</label
                      >
                      <div class="col-7">
                        <input
                          id="profile_margin"
                          v-model="company.profile.profile_average_margin"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-5 col-form-label">Sales Model</label>
                      <div class="col-7">
                        <!-- <ckeditor :editor="editor" v-model="salesModel" :config="editorConfig"></ckeditor> -->
                        <b-form-select
                          v-model="company.profile.profile_sales_model"
                          :options="salesModelOptions"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group row">
                      <label class="col-5 col-form-label">MDF %</label>
                      <div class="col-7">
                        <input
                          id="profile_mdf"
                          v-model="company.profile.profile_mdf_percent"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-5 col-form-label" for="gridCheck"
                        >Do you have a MAP Policy?
                      </label>
                      <div class="col-7">
                        <input
                          id="gridCheck"
                          v-model="company.profile.profile_map_policy"
                          class="form-check-input"
                          type="checkbox"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-5 col-form-label"
                        >Do you offer Drop Ship?
                      </label>
                      <div class="col-7">
                        <b-form-select
                          v-model="company.profile.profile_dropship"
                          :options="dropshipOptions"
                        ></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 col-form-label">MAP Policy</label>
                  <div class="col-9">
                    <ckeditor
                      v-model="company.profile.profile_map_text"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 col-form-label"
                    >Dealer Requirements WYSIWYG</label
                  >

                  <div class="col-9">
                    <ckeditor
                      v-model="company.profile.profile_dealer_requirements"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>

                    <pre class="mt-3 mb-0"></pre>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card flat>
              <v-card-text>
                <div class="form-group row">
                  <label class="col-5 col-form-label"
                    >Promo Search Description</label
                  >
                  <div class="col-7">
                    <input
                      id="profile_teaser"
                      v-model="company.profile.promo_text"
                      class="form-control"
                      type="text"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 col-form-label"
                    >Teaser Description WYSIWYG</label
                  >

                  <div class="col-9">
                    <ckeditor
                      v-model="company.profile.profile_teaser_description"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>

                    <pre class="mt-3 mb-0"></pre>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-3 col-form-label"
                    >Long Description WYSIWYG</label
                  >

                  <div class="col-9">
                    <ckeditor
                      v-model="company.profile.profile_long_description"
                      :editor="editor"
                      :config="editorConfig"
                    ></ckeditor>

                    <pre class="mt-3 mb-0"></pre>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-9"></div>

                  <div class="col-lg-3"></div>
                </div>
              </v-card-text>
            </v-card>

            <div v-if="company.profile.profile_cover" class="form-group row">
              <div class="col-12">
                <v-img
                  :src="
                    company.profile.profile_cover.url
                  "
                  height="200px"
                  alt="..."
                >
                </v-img>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-3 col-form-label">Page Header </label>

              <div class="col-9">
                <b-form-file
                  v-model="cover"
                  :state="Boolean(cover)"
                  :placeholder="
                    company.profile.profile_cover
                      ? company.profile.profile_cover.name
                      : 'Upload Image'
                  "
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                ></b-form-file>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="col-6">
                  <div
                    v-if="company.profile.profile_search_img"
                    class="form-group row"
                  >
                    <v-img
                      :src="
                        company.profile.profile_search_img.url
                      "
                      height="200px"
                      width="200px"
                      alt="..."
                    >
                    </v-img>
                  </div>
                </div>

                <div class="col-6">
                  <label class=" col-form-label">Search Thumbnail</label>

                  <b-form-file
                    v-model="searchImg"
                    :state="Boolean(searchImg)"
                    :placeholder="
                      company.profile.profile_search_img
                        ? company.profile.profile_search_img.name
                        : 'Upload Image'
                    "
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                  ></b-form-file>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div
                      v-if="company.profile.profile_desc_img"
                      class="form-group row"
                    >
                      <v-img
                        :src="company.profile.profile_desc_img.url
                        "
                        height="200px"
                        width="200px"
                        alt="..."
                      >
                      </v-img>
                    </div>
                  </div>

                  <div class="col-6">
                    <label class="col-form-label">Description Image</label>

                    <b-form-file
                      v-model="descImg"
                      :state="Boolean(descImg)"
                      :placeholder="
                        company.profile.profile_desc_img
                          ? company.profile.profile_desc_img.name
                          : 'Upload Image'
                      "
                      drop-placeholder="Drop file here..."
                      accept="image/*"
                    ></b-form-file>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-9"></div>

              <div class="col-lg-3">
                <button type="submit" class="btn btn-success mr-2">
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import GetCompanyProfile from '@/graphql/queries/GetCompanyProfile.gql'
export default {
  mixins: [validationMixin],
  data() {
    return {
      cover: null,
      searchImg: null,
      descImg: null,
      city: '',
      state: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      phone: '',
      website: '',
      category: '',
      categoryOptions: [
        {
          value: 'homegoods',
          text: 'Home Goods',
        },

        {
          value: 'health_beauty',
          text: 'Health & Beauty',
        },
        {
          value: 'food_beverage',
          text: 'Food & beverage',
        },
        {
          value: 'pets',
          text: 'Pet Products',
        },
        {
          value: 'outdoors',
          text: 'Outdoors & Water',
        },
        {
          value: 'automotive',
          text: 'Automotive',
        },
        {
          value: 'industrial',
          text: 'Industrial',
        },
        {
          value: 'telecom',
          text: 'RF & Telecom',
        },
        {
          value: 'electrical',
          text: 'Electrical',
        },
        {
          value: 'outdoors',
          text: 'Outdoor',
        },

        {
          value: 'fishing',
          text: 'Fishing',
        },
        {
          value: 'skiboard',
          text: 'Ski & Snowboard',
        },
        {
          value: 'sporting_goods',
          text: 'Sporting Goods',
        },
           {
          value: "commercial",
          text: "Commercial",
        },
        {
          value: 'mens_fashion',
          text: 'Mens Fashion',
        },
        {
          value: 'womens_fashion',
          text: 'Womens Fashion',
        },
        {
          value: 'kids_fashion',
          text: 'Youth Fashion',
        },
        {
          value: 'fashion_general',
          text: 'General Fashion',
        },

           {
          value: "health_beauty",
          text: "Health & Beauty",
        },
           {
          value: "replacementparts",
          text: "Parts & Components",
        },
          {
          value: "foodservice",
          text: "Food Service",

        },
      ],
      averageMargin: '',
      mdf: '',
      teaserText: '',
      mapPolicy: '',
      // hasPolicy: false,
      Published: false,
      dropship: 'yes',
      dropshipOptions: [
        {
          value: 'yes',
          text: 'Yes',
        },
        {
          value: 'no',
          text: 'No',
        },
      ],
      salesModel: '',
      salesModelOptions: [
        {
          value: 'Partners Only',
          text: 'Partners Only',
        },
        {
          value: 'Partners & Direct',
          text: 'Partners & Direct',
        },
      ],
      editor: ClassicEditor,
      teaserData: '',
      descData: '',
      dealerData: '',
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            '|',
            'insertTable',
            '|',
            'undo',
            'redo',
          ],
        },
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  apollo: {
    company: {
      query: GetCompanyProfile,
      variables() {
        return {
          id: this.$store.state.auth.user.company_relation,
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.getCompanyInfo({
        id: this.$store.state.auth.user.company_relation,
      }).then((res) => {
        if (res.data.type == 'supplier') {
          // var baseurl = ''
          // if (
          //   res.data.profile.profile_cover != null &&
          //   res.data.profile.profile_cover != undefined
          // ) {
          //   this.coverUrl = baseurl + res.data.profile.profile_cover.url
          //   this.cover_name = res.data.profile.profile_cover.name
          // }
          // if (
          //   res.data.profile.profile_search_img != null &&
          //   res.data.profile.profile_search_img != undefined
          // ) {
          //   this.searchImgUrl =
          //     baseurl + res.data.profile.profile_search_img.url
          //   this.searchImg_name = res.data.profile.profile_search_img.name
          // }
          // if (
          //   res.data.profile.profile_desc_img != null &&
          //   res.data.profile.profile_desc_img != undefined
          // ) {
          //   this.descImgUrl = baseurl + res.data.profile.profile_desc_img.url
          //   this.profile_desc_img_name = res.data.profile.profile_desc_img.name
          // }
          // this.profileId = res.data.profile.id
          // this.Published = res.data.profile.Published
          // this.companyname = res.data.profile.profile_company_name
          // this.city = res.data.profile.profile_city
          // this.state = res.data.profile.profile_state
          // this.email = res.data.profile.profile_contact_email
          // this.instagram = res.data.profile.instagram
          // this.linkedin = res.data.profile.linkedin
          // this.category = res.data.profile.profile_category
          // this.phone = res.data.profile.profile_phone
          // this.website = res.data.profile.profile_website
          // this.dropship = res.data.profile.profile_dropship
          // this.averageMargin = res.data.profile.profile_average_margin
          // this.teaserText = res.data.profile.promo_text
          // this.mdf = res.data.profile.profile_mdf_percent
          // if (
          //   res.data.profile.profile_map_text != null &&
          //   res.data.profile.profile_map_text != undefined
          // ) {
          //   this.mapPolicy = res.data.profile.profile_map_text
          // }
          // this.hasPolicy = res.data.profile.profile_map_policy
          // if (
          //   res.data.profile.profile_sales_model != null &&
          //   res.data.profile.profile_sales_model != undefined
          // ) {
          //   this.salesModel = res.data.profile.profile_sales_model
          // }
          // if (
          //   res.data.profile.profile_teaser_description != null &&
          //   res.data.profile.profile_teaser_description != undefined
          // ) {
          //   this.teaserData = res.data.profile.profile_teaser_description
          // }
          // if (
          //   res.data.profile.profile_long_description != null &&
          //   res.data.profile.profile_long_description != undefined
          // ) {
          //   this.descData = res.data.profile.profile_long_description
          // }
          // if (
          //   res.data.profile.profile_dealer_requirements != null &&
          //   res.data.profile.profile_dealer_requirements != undefined
          // ) {
          //   this.dealerData = res.data.profile.profile_dealer_requirements
          // }
        }
      })
    }, 1000)
  },
  validations: {
    email: {
      required,
      email,
    },
    companyname: {
      required,
    },
  },
  methods: {
    ...mapActions(['getCompanyInfo', 'updateProfileInfo']),
    validateState(name) {
      const { $dirty, $error } = this.$v[name]
      return $dirty ? !$error : null
    },
    submit(e) {
      e.preventDefault()
      if (this.company.profile) {
        this.updateProfileInfo({
          id: this.company.profile.id,
          profile_company_name: this.company.profile.profile_company_name,
          profile_city: this.company.profile.profile_city,
          Published: this.company.profile.Published,
          profile_state: this.company.profile.profile_state,
          profile_contact_email: this.company.profile.profile_contact_email,
          instagram: this.company.profile.instagram,
          linkedin: this.company.profile.linkedin,
          facebook: this.company.profile.facebook,
          profile_phone: this.company.profile.profile_phone,
          profile_website: this.company.profile.profile_website,
          profile_average_margin: this.company.profile.profile_average_margin,
          profile_mdf_percent: this.company.profile.profile_mdf_percent,
          profile_map_text: this.company.profile.profile_map_text,
          profile_map_policy: this.company.profile.profile_map_policy,
          profile_sales_model: this.company.profile.profile_sales_model,
          profile_teaser_description: this.company.profile
            .profile_teaser_description,
          profile_long_description: this.company.profile
            .profile_long_description,
          profile_dealer_requirements: this.company.profile
            .profile_dealer_requirements,
          profile_cover: this.cover,
          profile_search_img: this.searchImg,
          profile_desc_img: this.descImg,
          profile_category: this.company.profile.profile_category,
          promo_text: this.company.profile.promo_text,
          profile_dropship: this.company.profile.profile_dropship,
          profile_search_content:
            this.companyname +
            '_' +
            this.company.profile.profile_long_description +
            '_' +
            this.company.profile.profile_teaser_description,
        })
          .then(() => {
            Swal.fire({
              title: '',
              text: 'The application has been successfully updated!',
              icon: 'success',
              confirmButtonClass: 'btn btn-primary',
            })
            this.$apollo.queries.company.refetch()
            // var baseurl = ''
            // if (
            //   res.data.profile_cover != null &&
            //   res.data.profile_cover != undefined
            // ) {
            //   this.coverUrl = baseurl + res.data.profile_cover.url
            // }
            // if (
            //   res.data.profile_search_img != null &&
            //   res.data.profile_search_img != undefined
            // ) {
            //   this.searchImgUrl = baseurl + res.data.profile_search_img.url
            // }
            // if (
            //   res.data.profile_desc_img != null &&
            //   res.data.profile_desc_img != undefined
            // ) {
            //   this.descImgUrl = baseurl + res.data.profile_desc_img.url
            // }
          })
          .catch(() => {
            this.$bvToast.toast('An Error Occured!', {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
              solid: true,
              appendToast: true,
            })
          })
      } else {
        this.$bvToast.toast('No profile in the company.', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
        })
      }
    },
  },
}
</script>
